import { isBuiltInContextMenuVisible, isFallbackElement, isScriptAccessAllowed, isSwfFilename, isSwfMimeType, isYoutubeFlashSource, workaroundYoutubeMixedContent, RufflePlayer, } from "./ruffle-player";
import { WindowMode } from "./load-options";
import { registerElement } from "./register-element";
/**
 * A polyfill html element.
 *
 * This specific class tries to polyfill existing `<embed>` tags,
 * and should not be used. Prefer [[RufflePlayer]] instead.
 *
 * @internal
 */
export class RuffleEmbed extends RufflePlayer {
    /**
     * Constructs a new Ruffle flash player for insertion onto the page.
     *
     * This specific class tries to polyfill existing `<embed>` tags,
     * and should not be used. Prefer [[RufflePlayer]] instead.
     */
    constructor() {
        super();
    }
    /**
     * @ignore
     * @internal
     */
    connectedCallback() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
        super.connectedCallback();
        const src = this.attributes.getNamedItem("src");
        if (src) {
            const allowScriptAccess = (_b = (_a = this.attributes.getNamedItem("allowScriptAccess")) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null;
            const menu = (_d = (_c = this.attributes.getNamedItem("menu")) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : null;
            // Kick off the SWF download.
            this.load({
                url: src.value,
                allowScriptAccess: isScriptAccessAllowed(allowScriptAccess, src.value),
                parameters: (_f = (_e = this.attributes.getNamedItem("flashvars")) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : null,
                backgroundColor: (_h = (_g = this.attributes.getNamedItem("bgcolor")) === null || _g === void 0 ? void 0 : _g.value) !== null && _h !== void 0 ? _h : null,
                base: (_k = (_j = this.attributes.getNamedItem("base")) === null || _j === void 0 ? void 0 : _j.value) !== null && _k !== void 0 ? _k : null,
                menu: isBuiltInContextMenuVisible(menu),
                salign: (_m = (_l = this.attributes.getNamedItem("salign")) === null || _l === void 0 ? void 0 : _l.value) !== null && _m !== void 0 ? _m : "",
                quality: (_p = (_o = this.attributes.getNamedItem("quality")) === null || _o === void 0 ? void 0 : _o.value) !== null && _p !== void 0 ? _p : "high",
                scale: (_r = (_q = this.attributes.getNamedItem("scale")) === null || _q === void 0 ? void 0 : _q.value) !== null && _r !== void 0 ? _r : "showAll",
                wmode: (_t = (_s = this.attributes.getNamedItem("wmode")) === null || _s === void 0 ? void 0 : _s.value) !== null && _t !== void 0 ? _t : WindowMode.Window,
            });
        }
    }
    /**
     * Polyfill of HTMLObjectElement.
     *
     * @ignore
     * @internal
     */
    get src() {
        var _a;
        return (_a = this.attributes.getNamedItem("src")) === null || _a === void 0 ? void 0 : _a.value;
    }
    /**
     * Polyfill of HTMLObjectElement.
     *
     * @ignore
     * @internal
     */
    set src(srcval) {
        if (srcval) {
            const attr = document.createAttribute("src");
            attr.value = srcval;
            this.attributes.setNamedItem(attr);
        }
        else {
            this.attributes.removeNamedItem("src");
        }
    }
    /**
     * @ignore
     * @internal
     */
    static get observedAttributes() {
        return ["src", "width", "height"];
    }
    /**
     * @ignore
     * @internal
     */
    attributeChangedCallback(name, oldValue, newValue) {
        var _a, _b, _c, _d;
        super.attributeChangedCallback(name, oldValue, newValue);
        if (this.isConnected && name === "src") {
            const src = this.attributes.getNamedItem("src");
            if (src) {
                this.load({
                    url: src.value,
                    parameters: (_b = (_a = this.attributes.getNamedItem("flashvars")) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : null,
                    base: (_d = (_c = this.attributes.getNamedItem("base")) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : null,
                });
            }
        }
    }
    /**
     * Checks if the given element may be polyfilled with this one.
     *
     * @param elem Element to check.
     * @returns True if the element looks like a flash embed.
     */
    static isInterdictable(elem) {
        // Don't polyfill if the element is inside a specific node.
        if (isFallbackElement(elem)) {
            return false;
        }
        // Don't polyfill when no file is specified.
        if (!elem.getAttribute("src")) {
            return false;
        }
        // Don't polyfill when the file is a Youtube Flash source.
        if (isYoutubeFlashSource(elem.getAttribute("src"))) {
            // Workaround YouTube mixed content; this isn't what browsers do automatically, but while we're here, we may as well
            workaroundYoutubeMixedContent(elem, "src");
            return false;
        }
        // Check for MIME type.
        const type = elem.getAttribute("type");
        if (!type) {
            // If no MIME type is specified, polyfill if movie is an SWF file.
            return isSwfFilename(elem.getAttribute("src"));
        }
        else {
            return isSwfMimeType(type);
        }
    }
    /**
     * Creates a RuffleEmbed that will polyfill and replace the given element.
     *
     * @param elem Element to replace.
     * @returns Created RuffleEmbed.
     */
    static fromNativeEmbedElement(elem) {
        const externalName = registerElement("ruffle-embed", RuffleEmbed);
        const ruffleObj = document.createElement(externalName);
        ruffleObj.copyElement(elem);
        return ruffleObj;
    }
}
