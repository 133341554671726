/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-04-17",
    versionChannel: "nightly",
    buildDate: "2023-04-17T00:19:12.508Z",
    commitHash: "e8aa620767a4eea9cd1a40ceba0a6ca7ffee634e",
};
